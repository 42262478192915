*{
    margin: 0px;
    padding: 0px;
}

#celebrate-cont{
    display: grid;
    width: 90%;
    grid-template-columns: repeat(2,1fr);
    margin: auto;
    justify-content: space-evenly;
    overflow: hidden;
    padding: 10px;
    gap: 40px;
}
#celebrate-cont img{
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}
#celebrate-cont>img:hover{
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
}

@media all and (min-width: 768px) and (max-width: 1224px) {
   
#celebrate-cont{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        /* flex-wrap: wrap; */
    }
    
    
}
@media all and (min-width: 0px) and (max-width: 768px) {
    
    #celebrate-cont{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        /* margin-top: 1200px; */
        /* flex-wrap: wrap; */
    }


    

}