@media all and (min-width: 768px) and (max-width: 1224px) {
    .slider{
        width:100%;
        height: 100%;
    }
    
    
}
/* style={{marginBottom:"20px",minHeight:"100%",width:"23%",cursor:"pointer"}} */
@media all and (min-width: 0px) and (max-width: 768px) {
    
    .slider{
        width:100%;
        height: 140px;
        object-fit: cover;
    }
    
    

}