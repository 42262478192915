.cart {
  background-color: #f7f9fc;
  padding: 20px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.cart > div {
  width: 100%;
}
.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 15px;
  gap: 20px;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

/* .cart-item button {
  margin-left: 10px;
} */

.cartContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

/* Styling for the product image */
.cartContainer img {
  width: 400px;
  height: 100px;
}

.details{
  width: 350px;
  text-align: left;
}
.cart-prod-name {
  margin: 10px 0px;
  text-decoration: none;
}

/* Styling for the product price */
.cartContainer p {
  margin: 5px 0;
  font-size: 1rem;
  color: #888;
}

.quantity button {
  border: none;
  background-color: rgb(255, 65, 139);
  color: white;
  font-size: 1.5rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}
.quantity button:hover {
  background-color: rgb(222, 85, 138);
}
.quantity {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.quantity span {
  font-size: 1.2rem;
  margin: 0 10px;
}

.remove{
  width: 400px;
}
.remove>img{
  height: 20%;
  width: 20%;
  float: right;
  cursor: pointer;
}
.checkout {
  margin-top: 20px;
  padding-top: 10px;
  border-radius: 10px;
  margin-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.checkout > h3 {
  display: inline;
  margin-bottom: 10px solid white;
}

.checkout p {
  margin-bottom: 10px;
}

.checkout button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
}
.apply-button {
  /* padding: 5px 10px; */
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
}

.apply-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
  flex-direction: column;
  width: 100%;
}
.apply-button {
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5dbfc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b2c3dd;
  cursor: all-scroll;
}
@media all and (min-width: 490px) and (max-width: 1224px) {
  .cart {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
    /* flex-wrap: wrap; */
  }
  .cart > div {
    width: 100%;
  }

  .remove {
    display: block;
    margin: auto;
    /* border: 1px solid red; */
  }
  .checkout {
    height: auto;
  }
  .cart-prod-name {
    font-size: 0.8rem;
  }
}
/* style={{marginBottom:"20px",minHeight:"100%",width:"23%",cursor:"pointer"}} */
@media all and (min-width: 0px) and (max-width: 490px) {
  .cart {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
    /* flex-wrap: wrap; */
  }
  .cart > div {
    width: 100%;
  }
  .apply-button {
    width: 100%;
  }
  .apply-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
    flex-direction: column;
    width: 100%;
  }

  .cart-item > div:nth-child(1) {
    /* border: 1px solid rgb(30, 255, 0); */
    min-height: auto;
    min-width: 100%;
    padding: 20px;
  }
  .cart-item > div:nth-child(1) > img {
    width: 100%;
    min-height: auto;
    min-width: 80px;
    height: 100%;
  }
  .cart-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .quantity {
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
  }
  .remove {
    display: flex;
    justify-content: center;
    margin-left: 30px;
    height: 40%;
    width: 40%;
    /* border: 2px solid red; */
    /* border: 1px solid red; */
  }
}
