#links-cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  row-gap: 50px;
  margin: 20px;
}

.items {
  /* border: 1px solid #ccc; */
  width: 80%;
  height: auto;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;

  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    transition: transform 0.2s, box-shadow 0.2s; */
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease-in-out;
}
.items:hover {
  transform: scale(1.1);
}
.items img {
  width: 100%;
  height: 250px;
  margin: auto;
  border-radius: 10px;
}

.items a {
  font-size: 1.1rem;
  text-decoration: none;
  color: #333;
  width: 90%;
  display: block; /* Ensures the entire area is clickable */
  margin-top: 10px; /* Add margin to separate from h3 */
}
.items a:hover {
  color: rgb(255, 65, 139);
}
.items h3 {
  font-size: 1.2rem;
  margin: 0;
  color: #555;
  text-decoration: none;
}

/* Media Query for 2 cards per row */
@media (min-width: 768px) and (max-width: 1224px) {
  #links-cont {
    grid-template-columns: repeat(3, 1fr);
  }
  .items {
    height: 380px;
  }
}

/* Media Query for 1 card per row */
@media (max-width: 768px) {
  #links-cont {
    grid-template-columns: repeat(1, 1fr);
  }
  /* .items{
    height: 380px;
  } */
  .items img {
    width: 100%;
    height: 250px;
    margin: auto;
    border-radius: 10px;
  }
}
