#whatsapp{
    /* border: 1px solid green; */
    position: fixed;
    bottom: 50px;
    right: 20px;
    cursor: pointer;
    padding: 3px;
    z-index: 10;
    background: none;
    height: 60px;
    width: 60px;
    border-radius:60% ;
    border: none;
}
#whatsapp>img{
    border-radius:50% ;
    border: none;

}