.slider{
    width: 93%;
    /* height:100%; */
    display:block;
    margin:auto
}


.carousel-container>button{
    max-width: 20px;
    background:white;
    color: black;
    width: 10%;
    /* border: 1px solid red; */

}
.carousel-container>button .arrow--left{
border: 1px solid red;
    color: black;
   

}

.carousel-container-2{
    margin-top: 10px;
    height: auto;
}


.slider-container{
    height: 120%;
}


@media all and (min-width: 768px) and (max-width: 1224px) {
    .slider{
        width:50%;
        margin: auto;
        height: 300px;
    }
    
    
}
/* style={{marginBottom:"20px",minHeight:"100%",width:"23%",cursor:"pointer"}} */
@media all and (min-width: 0px) and (max-width: 768px) {
    
    .slider{
        width:100%;
        height:140px;
    }
    .carousel-container{
        width:100%;
        /* margin: auto; */

        height: auto;
        
    }
    .carousel-container>div>img{
        width:100%;
        height: 175px;
        object-fit: fill;

    }
    .carousel-container>button{
        width: 20%;
        background: red;
    }
    
    .carousel-container-2{
        height: auto;
    }
    

}