#products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* column-gap: 20px;
  row-gap: 10px; */
  width: 100%;
  /* line-height: 20px; */
}
#products > div {
  margin: auto;
}

#filters {
  display: flex;
  /* max-height: 200px; */
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-evenly;
  /* border: 1px solid gray; */
  flex-direction: column;
  margin-bottom: 40px;
}

#filter-cont {
  display: flex;
  justify-content: space-around;
  /* border: 1px solid red; */
  width: 100%;
  flex-wrap: wrap;
}

@media all and (min-width: 1228px) and (max-width: 4000px) {
  #products {
    display: grid;
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    column-gap: 10px;
  }
  #products > div {
    width: 100%;
    overflow: hidden;
  }
  #products > div img {
    width: 100%;
    height: 100%;

  }
}
@media all and (min-width: 768px) and (max-width: 1228px) {
  #products {
    display: grid;
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
  }
  #products > div {
    width: 90%;
    overflow: hidden;

  }
  #products > div img {
    width: 100%;
    height: 100%;
   
  }
}
/* style={{marginBottom:"20px",minHeight:"100%",width:"23%",cursor:"pointer"}} */
@media all and (min-width: 0px) and (max-width: 768px) {
  #products {
    display: grid;
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    gap: 5px;
  }
  #products>div {
    width: 100%;
    overflow: hidden;
  }
  #products > div img {
    width: 100%;
    height: 100%;
  }
}
