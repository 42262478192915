.disabled-date-picker {
  background-color: #f0f0f0; /* Light gray background */
  color: #a0a0a0; /* Light gray text */
  cursor: not-allowed;
}
.slotBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  width: 80%;
  margin: auto;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.41);
  text-align: left;
  margin-bottom: 10px;
}
.slotBox:hover {
  cursor: pointer;
  background-color: rgb(255, 65, 139);
  color: white;
}

/* .modal-scrollable-content {
  max-height: 800px;
  overflow-y: auto;
} */

.productCards {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    padding: 20px;
}
