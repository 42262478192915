.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .not-found-title {
    font-size: 6rem;
    font-weight: bold;
    color: #ff5c5c;
    margin-bottom: 2rem;
    animation: shake 0.5s infinite;
  }
  
  @keyframes shake {
    0% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    100% { transform: translateX(-5px); }
  }
  
  .not-found-text {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .not-found-image {
    width: 300px;
    height: 300px;
    margin-bottom: 2rem;
    animation: rotate 4s infinite linear;
  }
  
  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .not-found-link {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: #ff5c5c;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .not-found-link:hover {
    background-color: #e60000;
  }
  