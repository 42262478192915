
/* Typography */
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&family=Ubuntu:wght@300;400;700&display=swap');

/* h1, 
h2,
h3 {
    font-family: 'Lora', serif;
    font-weight: 400;
    color: #143774;
    margin-top: 0;
}
h1{
  font-size: 2rem;
  margin: 0;
} */
a {
    color: #1792d2;
}

a:hover,
a:focus {
    color: #143774;
}

strong {
    font-weight: 700;
}
.subtitle{
  font-size: 0.85rem;
  font-weight: 700;
  margin: 0;
  color: #1792d2;
  letter-spacing: 0.05em;
  font-family: 'Ubuntu Bold', sans-serif;
}
.article-title {
    font-size: 1.5rem;
}

.article-read-more,
.article-info {
    font-size: .875rem;
}

.article-read-more {
    color: #1792d2;
    text-decoration: none;
    font-weight: 700;
}
.article-read-more:hover,
.article-read-more:focus {
    color: #143774;
    text-decoration: underline;
}
.article-info {
    margin: 2em 0;
}
header{
  padding: 2em 0;
  text-align: center;
  background: #f0f8ff;
  font-family: sans-serif;
  margin-bottom: 3em;
}
.container-flex{
  max-width: 70vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}
nav ul{
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}
nav li{
  margin: 0 1em;
}
nav a{
  text-decoration: none;
  color: #707070;
  font-weight: 700; 
  padding: 0.25em 0;
}

nav a:hover,
nav a:focus{
  color: #143774;
  border-bottom: 1px solid black;
  transition: .3s ease;
}

img{
  max-width: 100%;
  display: block;
}
main{
  max-width: 75%;
}
.article-body{
  width: 100%;
  text-align: justify;
}
#sidebar{
  max-width: 23%;
}

@media (max-width:1050px){
  .container-flex{
    flex-direction: column;
  }
  .site-title, .subtitle{
    width: 100%;
  }
  main{
    max-width: 100%;
  }
  #sidebar{
    max-width: 100%;
  }
  
}
@media (max-width: 500px){
  nav ul{
    display: flex;
    flex-direction: column;
  }
  nav li{
    margin: 0.5em 0;
  }
}

/* articles */
.article-featured {
    border-bottom: #707070 1px solid;
    padding-bottom: 2em;
    margin-bottom: 2em;
}
.article-recent {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
}

.article-recent-main {
    order: 2;
}

.article-recent-secondary {
    order: 1;
}

@media (min-width: 675px) {
    .article-recent {
        flex-direction: row;
        justify-content: space-between;
    }
    
    .article-recent-main {
        width: 68%;
    }
    
    .article-recent-secondary {
        width: 30%;
    }
}

