.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    background-color: pink;
   
  }
  
  h2 {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form {
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    padding: 20px;
   
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: #333;
  }
  
  textarea {
    height: 150px;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  