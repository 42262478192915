body {
	font-family: Arial, Helvetica, sans-serif;
	margin: 0;
  }
  
  .menubar {
	overflow: hidden;
	background-color: white; 
  }
  
  .menubar a {
	float: left;
	font-size: 16px;
	color: black;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
  }
  
  .subnav {
	float: left;
	overflow: hidden;
  }
  
  .subnav .subnavbtn {
	font-size: 16px;  
	border: none;
	outline: none;
	color: black;
	padding: 14px 16px;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
  }
  
  .menubar a:hover, .subnav:hover .subnavbtn {
	background-color: red;
  }
  
  .subnav-content {
	display: none;
	position: absolute;
	left: 0;
	background-color: white;
	width: 100%;
	z-index: 1;
  }
  
  .subnav-content a {
	float: left;
	color: black;
	text-decoration: none;
  }
  
  .subnav-content a:hover {
	background-color: #eee;
	color: black;
  }
  
  .subnav:hover .subnav-content {
	display: block;
  }
  