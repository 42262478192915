.product-page {
  display: flex;
  justify-content: space-evenly;
  margin: 20px;
  background: #f7f9fc;
  /* border: 1px solid red; */
  text-align: start;
  /* min-width: 100%; */
  /* border: 1px solid red; */
  /* background-color:#f7f9fc ; */
}
.product-page > div {
  /* margin-top: 100px; */
}
.product-page div {
  background: white;
  /* border: 1px solid black; */
}
.product-page > div:nth-child(1) {
  /* border: 1px solid rgb(0, 255, 21); */
  height: 100%;
  width: 35%;
}
.product-page > div:nth-child(2) {
  width: 100%;
  /* border: 1px solid rgb(0, 255, 221); */
  margin: auto;
}
.product-page > div {
  /* border: 1px solid red; */
  background: transparent;
}

.addons-singleproduct {
  display: grid;
  margin-top: 15px;
  border: 1px solid gray;
  padding: 25px;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.product-page > div > div {
  min-width: 100%;
}
#cart-buttons {
  width: 100%;
  margin: auto;
  display: flex;
}

#offers-cont {
  border: 1px solid rgb(20, 20, 20);
  border-radius: 10px;
  height: auto;
  width: 100%;
  padding: 20px;
}
.offerbox {
  display: flex;
  justify-content: space-between;
}

/* .weight-buttons {
  border: 1px solid gray;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.weight-buttons.active {
  background: rgb(243, 80, 142);
  color: white;
}
 */

/* .weight-buttons {
  padding: 5px 10px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.selected {
  background-color: rgb(243, 80, 142);
}

.unselected {
  background-color: gray;
}
.selected:hover {
  background: rgb(194, 50, 105);
}
.unselected:hover {
  background-color: rgb(104, 80, 80);
}  */
.weight-buttons {
  padding: 5px 10px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  background-color: gray; /* Default background color */
}

.selected {
  background-color: rgb(243, 80, 142);
}

.unselected {
  background-color: gray;
}
.selected:hover {
  background: rgb(194, 50, 105);
}
.unselected:hover {
  background-color: rgb(104, 80, 80);
}
#cart-buttons > button {
  border-radius: 8px;
  width: 45%;
  font-size: 20px;
  cursor: pointer;
  background-color: #c709e8;
  color: white;
  padding: 5px;
  display: block;
  border: none;
  padding: 10px 15px;
  margin: auto;
  margin-bottom: 20px;
}
#cart-buttons > button:hover {
  background: #6e0b80;
}
.image-container {
  width: 300px; /* Adjust according to your requirements */
  height: 300px; /* Adjust according to your requirements */
  overflow: hidden;
}

.zoom-effect {
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.zoom-effect:hover {
  transform: scale(1.2);
}

.main-image img {
  width: 100%;
  height: auto;
}

.related-images {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.related-images img {
  width: 30%;
  height: auto;
}
.product-specifications {
  width: 100%;
  margin-top: 100px;
}
.product-details {
  /* flex-basis: 100%; */
  width: 100%;
  margin-left: 20px;
  background-color: white;
  /* border: 1px solid green; */
  padding: 15px;
}

.product-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-price {
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 10px;
}

.product-description {
  color: #666;
  margin-bottom: 20px;
}

.product-specifications {
  margin-bottom: 20px;
}

.product-specifications td {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.product-highlights > ul > li {
  margin-top: 5px;
  list-style: circle;
}
.product-highlights {
  width: 100%;
}
#prod_det {
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.date-cont {
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border: 1px solid red; */
}
#video-player {
  width: "100%";
}

@media all and (min-width: 768px) and (max-width: 1224px) {
  .product-page {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    padding: 5px;
  }

  .product-page > div:nth-child(1) {
    /* border: 1px solid rgb(0, 255, 21); */
    height: 100%;
    width: 100%;
    margin: auto;
    /* margin-top: 20px; */
  }
  .product-page > div:nth-child(2) {
    /* border: 1px solid red; */
    min-width: 100%;
    margin: auto;
    margin-top: 20px;
  }
  .product-details {
    width: 100%;
    margin: auto;
  }
  .product-highlights {
    width: 100%;
    font-size: 15px;
    margin: auto;
  }
  #video-player {
    width: "70%";
    /* border: 1px solid red; */
    margin: auto;
    /* object-fit: cover; */
  }
}

.date-cont {
  padding: 25px;
}
.cakecategory {
  gap: 10px;
}
.cakecategory > div {
  display: flex;
  /* border: 1px solid red; */
  align-items: center;
  gap: 5px;
}

.cakecategory > div > input {
  height: 15px;
  width: 15px;
}
/* style={{marginBottom:"20px",minHeight:"100%",width:"23%",cursor:"pointer"}} */
@media all and (min-width: 0px) and (max-width: 768px) {
  .product-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
  .product-page > {
    width: 100%;
  }
  .product-page > div:nth-child(1) {
    /* border: 1px solid rgb(0, 255, 21); */
    height: 100%;
    margin: auto;
    width: 100%;
    margin-top: 20px;
  }
  .product-details {
  }
  .product-page > div:nth-child(2) {
    /* border: 1px solid red; */
    min-width: 100%;
    margin: auto;
    margin-top: 20px;
  }
  #video-player {
    width: "50%";
    margin: "auto";
    border: 1px solid green;
  }
  #video-player > video {
    border: 1px solid green;
  }
  .product-details {
    width: 100%;
  }
  .product-highlights {
    width: 90%;
    font-size: 10px;
    padding: 25px;
    margin: auto;
    height: 50%;
  }
  #offers-cont {
    font-size: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
  }
  .offerbox {
    display: flex;
    align-items: center;
  }
  .offerbox > img {
    width: 25%;
    height: 25%;
    margin-right: 15px;
  }
  #cart-buttons {
    gap: 10px;
  }
  #cart-buttons > button {
    width: 80%;
    font-size: 15px;
  }

  .addons-singleproduct {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
