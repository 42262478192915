* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .navdrop {
    position: relative;
    background-color: #fff;
    padding-left: 55px;
  }
  
  .navdroplist {
    display: flex;
    align-items: center;
    width: 80%;
    /* margin: auto; */
    justify-content: space-around;
    gap: 12px;
  }
  
  .navdroplist > li {
    text-align: center;
    border: 2px solid #fff;
    padding: 7px 0;
    font-size: 0.9rem;
    text-transform: capitalize;
    list-style-type: none;
  }
  
  .navdropheader {
    padding: 3px 6px;
  }
  
  .navdroplist > li > a {
    color: #0f0f0f;
    display: block;
  }
  
  .navdroplist > li:hover {
    border-bottom: 2px solid rgb(255, 65, 139);;
  }
  
  .navdroplist > li:hover .navdropheader {
    background-color: #f8eef8;
    border-radius: 5px;
  }
  
  .navdropper {
    display: none;
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    width: 100%;
  }
  
  .navdroplist > li:hover .navdropper {
    display: block;
  }
  
  @media screen and (max-width: 969px) {
    .navdrop {
      display: none;
    }
  }