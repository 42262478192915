*{
    margin: 0px;
    padding: 0px;
}

#header{
 /* border: 1px solid rgb(197, 26, 26); */
 width: 100%;
 margin: auto;
 /* width: 90%;
 height: 100px;
 min-height: 100px;
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
  margin: auto;
 margin-bottom: 5px; */
}
.headers-div{
/* border: 1px solid red; */
height: auto;
align-items: center;

}

.headers-div>p{
    display: block;
    margin: auto;
    text-align: center;

}
.headers-div>img{ 
transition: 0.5s ease-in-out;
height: 100%;
cursor: pointer;
display: block;
margin: auto;
width: 100%;
border:"1px solid green"

}
.headers-div img:hover{
    margin-top: -10px;
}

@media all and (min-width: 768px) and (max-width: 1224px) {
    
    .headers-div p{
        font-size: 12px;
     }
    
}
/* style={{marginBottom:"20px",minHeight:"100%",width:"23%",cursor:"pointer"}} */
@media all and (min-width: 0px) and (max-width: 768px) {
    

    .headers-div p{
        font-size: 10px;
     }
     .headers{
        width: 50%;
     }

    /* .abc > ul > li{
        border: 1px solid blue;
        width: 50px;
    } */

}